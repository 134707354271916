import Card from 'Clutch/Atoms/Card'
import Divider from 'Clutch/Atoms/Divider'
import Gutters from 'Clutch/Atoms/Gutters'
import Panel from 'Clutch/Atoms/Panel'
import TextLink from 'Clutch/Atoms/TextLink'
import Typography from 'Clutch/Atoms/Typography'

export default function Home() {
  return (
    <Panel>
      <Gutters>
        <Card>
          <Typography size={1.5} font={'heading'}>
            Shop Pages
          </Typography>
          <Divider />
          <TextLink href={'/shop/speedway~1-10191'} intent={'action'} size={1}>
            Single Facet - Single Select
          </TextLink>
          <br />
          <TextLink
            href={'/shop/speedway~1-10191_10038'}
            intent={'action'}
            size={1}
          >
            Single Facet - Multi Select
          </TextLink>
          <br />
          <TextLink
            href={'/shop/speedway~1-10191?facet=l_material_type:34'}
            intent={'action'}
            size={1}
          >
            Multiple Facets - Single Select
          </TextLink>
          <br />
          <TextLink
            href={'/shop/speedway~1-10191_10038?facet=l_material_type:34'}
            intent={'action'}
            size={1}
          >
            Multiple Facets - Multi Select
          </TextLink>
          <br />
          <TextLink href={'/shop/chevy~32-47'} intent={'action'} size={1}>
            Make Facet - Use to test Vehicle facet rules
          </TextLink>
        </Card>
        <Card>
          <Typography size={1.5} font={'heading'}>
            Search Pages
          </Typography>
          <Divider />

          <TextLink href={'/search?query=tools'} intent={'action'} size={1}>
            Query
          </TextLink>
          <br />
          <TextLink
            href={'/search?query=fird%20engine'}
            intent={'action'}
            size={1}
          >
            AutoCorrected Query
          </TextLink>
          <br />
          <TextLink
            href={'/search?query=tools&facet=GA_Brand:Accel'}
            intent={'action'}
            size={1}
          >
            Query with Global Facet
          </TextLink>
          <br />
          <TextLink
            href={'/search?query=tools&facet=l_material_type:34'}
            intent={'action'}
            size={1}
          >
            Query with Local Facet
          </TextLink>
          <br />
          <TextLink
            href={
              '/search?query=tools&facet=GA_Brand:Speedway*L_Material_Type:34'
            }
            intent={'action'}
            size={1}
          >
            Query with Global and Local Facets
          </TextLink>
          <br />
          <TextLink
            href={'/search?query=asldkfjsal'}
            intent={'action'}
            size={1}
          >
            No Result Page
          </TextLink>
        </Card>
        <Card>
          <Typography size={1.5} font={'heading'}>
            SEO
          </Typography>
          <Divider />
          <TextLink href={'/'} intent={'action'} size={1}>
            Index & Follow
          </TextLink>
          <br />
          <TextLink href={'/'} intent={'action'} size={1}>
            Index & No Follow
          </TextLink>
          <br />
          <TextLink href={'/'} intent={'action'} size={1}>
            No Index & No Follow
          </TextLink>
          <br />
          <TextLink href={'/'} intent={'action'} size={1}>
            Redirect
          </TextLink>
        </Card>
      </Gutters>
    </Panel>
  )
}
